import { observable } from 'mobx';

const USER_TYPE_MAP = {
  0: 'Employee',
  1: 'Supervisor',
  2: 'Admin',
  3: 'Expert',
  4: 'DOKK',
};

export const STATUS_BAN_MAP = {
  Cancelled: 'Отменен',
  Open: 'Открыт',
  Suspended: 'Приостановлен',
  Tentative: 'Предварительный',
  null: 'Открыт',
};

export type TypeStatusBan = keyof typeof STATUS_BAN_MAP

export type UInfo = {
  appType: 0 | 1 | 2,
  id: number,
  name: string | null,
  firstName: string | null,
  middleName: string | null,
  lastName: string | null,
  parentId: string | null,
  userType: string | null,
  status: string | null,
  createDate: string | null,
  email: string | null,
  isExternalUser: boolean,
  customerSettings: {
    withInternalTariff: boolean,
    autoMoveToModeration: boolean,
    allowSetCompanyDetails: boolean,
    disabledBanConfirmation: boolean,
    isUUIDVisibility: boolean,
  },
  customerInfo: {
    companyId: number,
    ban: string | null,
    contractNumber: string | null,
    customerSettings: string | null,
    emails: string | null,
    id: number,
    industryBeePro: string | null,
    inn: string | null,
    isPostPay: boolean,
    manager: null,
    name: string | null,
    socialVkontakte: string | null,
    website: string | null,
    isSelfEmployee: boolean,
    testSmsLimit: number,
    edoId: number | null,
    edoName: string | null,
    kpp: string | null,
    ogrn: string | null,
    type: 0 | 1 | 2,
    isBeePro: boolean,
  },
  supervisor: null | {
    firstName: string,
    id: number,
    middleName: string,
    username: string,
    lastName: string,
  }
  personalInfo: null | {
    birthdayDate: string,
    docSerial: string | null,
    docNumber: string | null,
    docIssueDate: string | null,
    issuedByWhom: string | null,
    docIssuerCode: string | null,
    regAddress: string | null,
    postalCode: string | null,
    flat: string | null,
    isActualAddressSameAsLegal: boolean,
    actualAddress: string | null,
    actualFlat: string | null,
    actualPostalCode: number,
  },
  region: string | null,
  regionId: string | null,
  offerAcceptedDate: string | null,
  troubleShootMessage: string | null,
  isSbpAllowed: boolean,
}
// appType:
// All (сотрудник Билайн, кабинет СС) = 0,
// Internal (партнер в ТСМС, в БиПро вроде не ходит) = 1,
// External (интерфейс БиПро, для внешних пользователей: малышарик бипро, партнер, агенство) = 2

// customerInfo.type -> company.type:
// Простой бипрошник = 0
// Partner = 1
// Agency = 2

export type LegacyUInfo = {
  appType: 0 | 1 | 2,
  id: number,
  email: string | null,
  username: string | null,
  first_name: string | null,
  last_name: string | null,
  middle_name: string | null,
  phone: string | null,
  parentId: string | null,
  company: {
    companyId?: number,
    id?: number,
    name: string | null,
    website: string | null,
    social_vkontakte: string | null,
    inn: string | null,
    ban: string | null,
    post_pay: boolean,
    industry: string | null,
    isSelfEmployee: boolean,
    edoId: number | null,
    edoName: string | null,
    type: 0 | 1 | 2,
    kpp: string | null,
    ogrn: string | null,
  },
  supervisor: null | {
    firstName: string,
    id: number,
    middleName: string,
    username: string,
    lastName: string,
  },
  personalInfo: null | {
    birthdayDate: string,
    docSerial: string | null,
    docNumber: string | null,
    docIssueDate: string | null,
    issuedByWhom: string | null,
    docIssuerCode: string | null,
    regAddress: string | null,
    postalCode: string | null,
    flat: string | null,
    isActualAddressSameAsLegal: boolean,
    actualAddress: string | null,
    actualFlat: string | null,
    actualPostalCode: number,
  },
  late_payment: boolean, // это надо брать на бэке
  type: string | null,
  offer_accepted_date: string | null,
  userType: string | null,
  isSbpAllowed: boolean,
};

export type TypeDataParent = {
  createDate: string | null,
  email: string | null,
  firstName: string | null,
  id: number,
  lastName: string | null,
  middleName: string | null,
  name: string | null,
  parentId: number | null,
  regionId: number | null,
  status: number,
  userType: number
}

class UserInfo {
  @observable data: null | UInfo = null;
}

const TSMSUserInfo = new UserInfo();
export { TSMSUserInfo };
